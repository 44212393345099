<template>
  <div class="test">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell
        v-for="item in productList"
        :key="item.productId"
        :title="item.masterName"
      />
    </van-list>
  </div>
</template>

<script>
import $http from "@/utils/http";
import Son from "./son.vue";
export default {
  components: {
    Son,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      productList: [],
      currPage: 1,
      isLoading: false,
    };
  },
  methods: {
    onLoad() {
      
      let params = { currPage: this.currPage };

      $http.get("/product/list", { params }).then((res) => {
        this.currPage++;
        this.productList = [...this.productList, ...res.list];
        this.loading = false;

        if (res.list.length == 0) {
          this.finished = true;
        }
        console.log(this.productList);
      });
    },
  },
};
</script>
<style lang="less"></style>
